//React
import React, { useEffect } from "react"
import { connect } from "react-redux"

// //Gatsby
// import { graphql } from "gatsby"
// import Img from "gatsby-image"

//Actions
import { loadPublisherTemplates } from "../actions/createPublisher"

//Packages
// import Helmet from "react-helmet"
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { navigate } from "@reach/router"

import parse from "url-parse"

//Components
import Loading from "../components/loading"
// import LoginScreen from "../components/appLogin/loginScreen"
// import LoginMarketing from "../components/appLogin/loginMarketing"

// //Icons
// import { TiTimes } from "react-icons/ti"

//Redux
// const mapStateToProps = ({ campaign, sendgroup, templatetags, isLoading }) => {
//   return { campaign, sendgroup, templatetags, isLoading }
// }

const mapDispatchToProps = {
  loadPublisherTemplates,
}

const Snippet = (props) => {
  useEffect(() => {
    //like componentDidMount
    const { loadPublisherTemplates } = props
    const url = parse(window.location.href, true)

    const templateid = url.query.t
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("url")
        console.log(url)
        console.log("templateid")
        console.log(templateid)
        loadPublisherTemplates({ id: templateid, context: "image" })
      })
      .catch((error) => {
        navigate(`/?p=edit&t=${templateid}`)
      }) //end get Auth token
  }, [])
  return (
    <div class="vh-100 d-flex align-items-center justify-content-center">
      <Loading />
    </div>
  )
}

// export default Snippet
export default connect(null, mapDispatchToProps)(Snippet)
