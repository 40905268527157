//React
import React from "react"

const Snippet = props => (
  <div
    className="row d-flex justify-content-center align-items-center"
    style={{ height: props.height ? props.height : "100%" }}
  >
    <div className={`typing ${props.class ? props.class : ""}`}>
      <div />
      <div />
      <div />
    </div>
  </div>
)

export default Snippet
